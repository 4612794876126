<template>
  <div v-if="displayButton" v-on:click="scrollToTop()" class="bg-primary position-fixed rounded flex scroll-button">
    <ArrowUpThick :fill-color="'white'" :size=24 />
  </div>
</template>

<script>
import ArrowUpThick from 'vue-material-design-icons/ArrowUpThick.vue';

export default {
    name: "ScrollUp",
    components: {
        ArrowUpThick
    },
    data() {
        return {
            displayButton: false,
            loading: false,
            currentTimeout: null
        }
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll, true)
    },
    methods: {
        scrollToTop() {
            window.scrollTo({top: 0, behavior: 'smooth'})
        },
        onScroll() {
            let windowTop = document.querySelector("#app").getBoundingClientRect().top;
            //clear timeout if we arent done scrolling
            window.clearTimeout(this.currentTimeout)
            if (!this.loading) {
                this.currentTimeout = setTimeout(() => {
                    //scrolling has ended
                    if (windowTop < -400) {
                        this.loading = true
                        this.displayButton = true
                        setTimeout(() => {
                            //give time for the button to render
                            let scrollUpButton = document.querySelector(".scroll-button")
                            if (scrollUpButton) scrollUpButton.style.opacity = 1
                            this.loading = false
                        }, 350)
                    } else {
                        window.clearTimeout(this.currentTimeout)
                        this.loading = true;
                        let scrollUpButton = document.querySelector(".scroll-button")
                        if (scrollUpButton) scrollUpButton.style.opacity = 0
                        setTimeout(() => {
                            this.displayButton = false;
                            this.loading = false;
                        }, 750)
                    }
                }, 66)
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll, true)
    }
}
</script>

<style scoped lang="scss">


.scroll-button {
    height: 50px;
    width:50px;
    bottom:20px;
    right: 20px;
    display:flex;
    align-items:center;
    justify-content: center;
    transition: opacity .5s ease-in-out .5s;
    opacity:0;

    :hover {
      cursor: pointer;
    } 
}
</style>