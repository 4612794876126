<template>
  <div>
    <v-subheader @click.stop v-if="title">{{ title }}</v-subheader>
    <v-list-item-group :value="selectedValue" @change="setSelectedValue" :multiple="multiple" light>
      <v-list-item
        @click.stop="setSelect(item[itemValue])"
        :dense="multiple"
        light
        selectable
        v-for="item in searchedList"
        :key="item[itemValue]"
        :value="item[itemValue]"
        #default="{ active }"
        color="primary"
      >
        <v-list-item-action v-if="multiple">
          <v-checkbox :ripple="false" :input-value="active" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-row no-gutters align="center">
              {{ item[itemText] }} <span v-if="itemCount">({{ item[itemCount] }})</span>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </div>
</template>
<script>

export default {
  name: "SearchTopSelector",
  watch: {
    selectedValueKey: function(selected) {
      this.$emit("setValue", selected);
    }
  },
  props: {
    selectedValue: [Array, String, Number],
    itemValue: String,
    itemText: String,
    title: { type: String, required: false },
    itemCount: String,
    multiple: Boolean,
    selected: [Array, Object, Number],
    searchedList: Array
  },
  methods: {
    setSelectedValue(SelectedValue) {
      this.$emit("update:selectedValue", SelectedValue);
    },
    setSelect(val) {
      this.$emit("applySingle", val);
    }
  }
};
</script>
