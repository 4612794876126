var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-card-wrapper"},_vm._l((_vm.listingsWithExtraInfoCards),function(carDetail,idx){return _c('div',{key:carDetail?.ListingId ? carDetail.ListingId : 'info' + idx,staticClass:"search-card"},[(idx === 4)?_c('div',{staticClass:"rounded shadow-sm border border-primary d-flex flex-column justify-content-center align-items-center text-center p-3 gap-2",staticStyle:{"height":"439px"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/car-step-1.svg"),"alt":"Car Icon","width":"52px"}}),_c('h4',{staticClass:"family-poppins"},[_vm._v(" "+_vm._s(_vm.$t('Buy.search.Interested'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('Buy.search.discoverHow'))+" ")]),_c('router-link',{staticClass:"py-2 px-5 rounded-pill btn btn-outline-primary learn-more-btn",attrs:{"to":{
            name: 'HelpInformation',
            params: {
              subsection: _vm.$t('Footer.Footer.Buyers').toLowerCase(),
              type: _vm.$t('HelpCenter.Buyer.BuyWithUs')
                .replaceAll(' ', '-')
                .toLowerCase()
            }}}},[_vm._v(" "+_vm._s(_vm.$t('global.label.learnMore'))+" ")])],1):(idx === 10)?_c('div',{staticClass:"rounded shadow-sm border border-primary d-flex flex-column justify-content-center align-items-center text-center p-3 gap-2",staticStyle:{"height":"439px"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/car-step-1.svg"),"alt":"Car Icon","width":"52px"}}),_c('h4',{staticClass:"family-poppins"},[_vm._v(" "+_vm._s(_vm.$t('Buy.search.HaveQuestions'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('Buy.search.HereForYou'))+" ")]),_c('button',{staticClass:"py-2 px-5 rounded-pill btn btn-outline-primary learn-more-btn",on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" "+_vm._s(_vm.$t('global.label.askNow'))+" ")])]):_c('TheCarCard',{staticClass:"mx-auto",attrs:{"carDetail":carDetail,"price":carDetail.Price,"includeFees":_vm.includeFees,"wishlist":""}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }