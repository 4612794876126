<template>
    <div v-if="!visited" id='clearance-notif-container' class="container d-flex justify-content-lg-end top-arrow p-0">
            <div id="clearance-notif" class=" bg-white border-muted shadow-sm rounded p-3">
                <div>
                    {{$t('Buy.search.notifDesc')}}
                    <div class="w-100 border-bottom my-3"/>
                    <!-- {{ $t("Overview.TotalCost") }}
                  <span style="text-transform:uppercase;font-weight: 500;">
                    {{  
                      includeFees ? $t("Overview.including") : 
                      $t("Overview.excluding") 
                    }}
                  </span>
                  {{ $t("Overview.DescCustomDutiesAndTaxes")}}. -->
                  <div class="px-3">
                    <div class="form-check mb-2">
                      <input class="form-check-input" type="radio" name="flexRadioDefault" :value="true" id="flexRadioDefault1" v-model="includeFeesOption">
                      <label class="form-check-label" for="flexRadioDefault1" v-html="$t('Buy.search.IncludeInPrice')"/>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="flexRadioDefault" :value="false" id="flexRadioDefault2" v-model="includeFeesOption">
                      <label class="form-check-label" for="flexRadioDefault2" v-html="$t('Buy.search.ExcludeInPrice')"/>
                    </div>
                </div>
                </div>
                <div class="mt-2 d-flex gap-2">
                    <!-- <button class="btn btn-light rounded shadow-sm" @click="closeNotif()">
                        {{ $t('Validations.email.gotIt') }}
                    </button> -->
                    <button class="btn btn-primary rounded shadow-sm" @click="handleChangeClearance()">
                        {{ $t('global.button.ok') }}
                    </button>
                </div>
            </div>
            <div class="position-fixed w-100 h-100 bg-dark" 
            style="top:0; left:0;opacity:50%;z-index:1;" 
            />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import includeFees from '@/components/mixins/includeFees.js'

export default {
    data() {
        return {
            includeFeesOption: undefined,
            visited: false
        }
    },
    mixins: [includeFees],
    computed: {
        ...mapGetters([
      "getTotalPrice"
    ]),
    },
    methods: {
        ...mapActions(['setListingPrices']),
        closeNotif() {
            this.visited = true;
            localStorage.setItem('visitedSearch', true);
            document.getElementsByTagName("body")[0].classList.remove("modal-open")
            document.getElementsByClassName("SearchBarHeader")[0].style.zIndex = 3
        },
        async handleChangeClearance() {
            this.$emit("setLoading", true)
            this.includeFees = this.includeFeesOption;
            let listingsPrices = await this.getTotalPrice();
            this.setListingPrices(listingsPrices.data)
            this.closeNotif();
            this.$emit("setLoading", false)
        }
    },
    mounted() {
        this.visited = localStorage.getItem('visitedSearch')
        let body = document.getElementsByTagName("body")[0];
        if (!this.visited) {
            //for if storage is cleared and user goes to the search page after going to a reserved listing using the return button. 
            //Would otherwise cause message to appear below header
            this.includeFeesOption = this.includeFees;
            document.getElementsByClassName("SearchBarHeader")[0].style.zIndex = 1
            body.classList.add("modal-open")
        } else {
            document.getElementsByClassName("SearchBarHeader")[0].style.zIndex = 3
            body.classList.remove("modal-open")
        }
    }
}
</script>

<style lang="scss" scoped>
#clearance-notif-container {
    height:0;
}
#clearance-notif {
    z-index:2;
    position:relative;
    top: -55px;
    height:fit-content;
    max-width: 460px;
}
.top-arrow:before {
    content:'';
    position: relative;
    left: 95px;
    bottom: 10px;
    width: 0;
    height: 0;
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index:2;
    top: -65px;

    @media screen and (min-width:992px) {
        left: 288px;
    }
}
</style>