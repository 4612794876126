<template>
  <div class="container mb-3 mb-md-7">
    <div class="p-3 p-md-4 rounded border border-muted bg-white">
      <div
        class="d-flex gap-2 justify-content-between align-items-center flex-column flex-md-row"
        v-if="found === null"
      >
        <h5 class="mb-0">
          {{  $t('search.label.findLookingFor') }}
        </h5>
        <div class="d-flex gap-2">
          <button
          class="btn btn-primary rounded-pill px-5"
          @click="sendFeedback(true)"
          >
          {{  $t('global.button.yes') }}
        </button>
        <button
        class="btn btn-secondary rounded-pill px-6"
        @click="found = false"
        >
        {{  $t('global.button.no') }}
      </button>
    </div>
  </div>
  <h5 class="mb-0" v-else-if="found === true">
    {{  $t('global.message.feedback') }}
      </h5>
      <div v-else-if="found === false" class="my-3 d-flex flex-column gap-3 mx-auto" style="max-width: 600px;">
        <h5 class="text-center text-md-left">
          {{ $t('search.label.contactGetInTouch') }}
        </h5>
        <b-form>
          <b-form-group class="w-100 field">
            <label class="form-label">
              {{ $t("Modal.CountryNotFound.Name") }}
            </label>

            <b-input-group>
              <b-form-input
                type="text"
                v-model="name"
                :state="nameValid"
                class="match-input-size input-pills"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group class="w-100 field">
            <label class="form-label">
              {{ $t("signIn.label.emailAddress") }}
            </label>
            <b-input-group>
              <b-form-input
                type="email"
                class="match-input-size input-pills"
                v-model="email"
                :state="emailValid"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group cols="12" class="field">
            <label class="form-label">
              {{ $t("signIn.label.phoneNumber") }}
            </label>
            <VuePhoneNumberInput
              show-code-on-list
              class="p-0"
              v-model.trim="mobile"
              :default-country-code="
                $store.getters.country.Code ? $store.getters.country.Code : 'CA'
              "
              noExample
              @update="setMobilePayload($event)"
              :translations="{
                countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                countrySelectorError: $t('Contact.countrySelectorError'),
                phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                example: $t('Contact.example'),
              }"
            />
          </b-form-group>
          <div class="text-danger" v-if="errorMessage">
            {{ errorMessage }}
          </div>
          <b-form-group class="mt-7 mb-0 field">
            <b-button
              variant="primary"
              class="mx-auto input-pills px-10 sendFormButton"
              @click="send()"
            >
              {{ $t("Modal.CountryNotFound.Submit") }}
            </b-button>
          </b-form-group>
        </b-form>
      </div>
    </div>
    <MessageSuccess/>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import phoneMixin from "@/components/mixins/phoneMixin";
import MessageSuccess from "@/components/global/modals/MessageSuccess";
import axios from 'axios';
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      found: null,
      mobile: null,
      name: null,
      nameValid: null,
      email: null,
      emailValid: null,
      emailFormat: /\S+@\S+\.\S+/,
      errorMessage: null
    };
  },
  mixins: [phoneMixin],
  components: {
    VuePhoneNumberInput,
    MessageSuccess
  },
  computed: {
    ...mapGetters(["destinationCountryCode"]),
  },
  methods: {
      send() {
        try {
          const valid = this.isFormValid()
          if (valid) {
                let formData = new FormData;
                formData.append('Name',  this.name)
                formData.append('Subject',  'Could not find car')
                formData.append('TypeId',  0)
                formData.append('CountryCode',  this.destinationCountryCode)
                if (this.email) formData.append('Email',  this.email)
                if (this.mobile) formData.append('Mobile',  this.mobile)
                axios.post("Basic/AddContactForm", formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data;'
                      }
                    })
                    .then(() => {
                      this.sending = false;
                      this.$bvModal.show("messageSendSuccess");
                      this.clearFields()
                    })
                    .catch((error) => {
                      this.errorMessage = error.toString();
                      this.sending = false;
                    })
              }
        } catch (err) {
              console.log(err)
            }
    },
    clearFields() {
      this.found = null
      this.mobile = null
      this.name = null,
      this.nameValid = null,
      this.email = null,
      this.emailValid = null,
      this.errorMessage = null
    },
    sendFeedback(feedback) {
      this.found = feedback;
      // api feedback data collection?
    },
    isEmail() {
      if (this.email) {
        if (this.emailFormat.test(this.email)) {
            this.emailValid = true;
            return true;
        }
      }
      this.emailValid = false;
      return this.emailValid;
    },
    isFormValid() {
      const isEmail = this.isEmail()
        if (!this.name) {
          this.nameValid = false
          this.errorMessage = "Please enter a valid name"
        }
        else if (!isEmail) this.errorMessage = "Please enter a valid Email"
        else if (!this.mobilePayload?.isValid) this.errorMessage = "Please enter a valid Phone #"

        //check if we have atleast one valid contact, and that the other field isnt empty or invalid
        let contactsValid = isEmail && this.mobile === null || this.mobilePayload?.isValid && !this.email || this.mobilePayload?.isValid && isEmail
        if (this.name) this.nameValid = true
        if (this.name && contactsValid) this.errorMessage = ""
      return (
        this.nameValid &&
        contactsValid
      );
    },
  },
};
</script>

<style></style>
