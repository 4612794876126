<template>
  <v-menu
    :attach="isMobile ? `#Year_Menu_Mobile` : `#Year_Menu`"
    max-width="400px"
    max-height="400px"
    content-class="menuClass"
    v-model="isOpen"
  >
    <template v-slot:activator="{ on, attrs }">
      <TopFilterBtn
        :label="$t('search.label.year')"
        :attrs="attrs"
        :on="on"
        :id="isMobile ? `Year_Menu_Mobile` : `Year_Menu`"
      />
    </template>
    <v-card width="300px">
      <v-card-title @click.stop> {{ $t('search.label.SelectYear') }}</v-card-title>
      <v-card-text @click.stop>
        <v-row>
          <v-col cols="6">
            <v-text-field :value="selected[0]" :label="$t('search.label.from')" filled readonly dense />
          </v-col>
          <v-col cols="6">
            <v-text-field :value="selected[1]" :label="$t('search.label.to')" filled readonly dense />
          </v-col>
        </v-row>
        <v-range-slider dense :min="min" :max="max" v-model="selected" hide-details />
      </v-card-text>
      <v-card-actions @click.stop>
        <v-row>
          <v-col cols="5" />
          <v-col cols="7" class="text-right">
            <v-btn small text class="mr-1" @click="clear"> {{ $t('search.label.Clear') }}  </v-btn>
            <v-btn small color="primary" @click="setSelectedValue"> {{ $t('search.label.Apply') }} </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import TopFilterBtn from "./TopFilterBtn";
export default {
  name: "YearSelector",
  props: ["selectedValue", "min", "max", "isMobile"],
  watch: {
    isOpen(val) {
      if (val && this.selectedValue) {
        if (this.selectedValue.length > 1) {
          this.selected = this.selectedValue;
        } else {
          this.selected = [this.min, this.max];
        }
      }
      if (!val) this.closeMenu();
    },
  },
  data() {
    return {
      selected: [],
      isOpen: false,
    };
  },
  components: {
    TopFilterBtn,
  },
  methods: {
    closeMenu() {
      this.isOpen = false;
      this.selected = [];
    },
    setSelectedValue() {
      this.$emit("apply", { selected: this.selected });
      this.closeMenu();
    },
    clear() {
      this.$emit("apply", { selected: [] });
      this.closeMenu();
    },
  },
};
</script>
