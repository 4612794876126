<template>
  <div class="search-card-wrapper">
    <div class="search-card" v-for="carDetail, idx in listingsWithExtraInfoCards" :key="carDetail?.ListingId ? carDetail.ListingId : 'info' + idx">
      <!-- Interested -->
      <div class="rounded shadow-sm border border-primary d-flex flex-column justify-content-center align-items-center text-center p-3 gap-2" style="height:439px;" v-if="idx === 4">
        <img src="@/assets/img/car-step-1.svg" alt="Car Icon" class="img-fluid" width="52px"/>
        <h4 class="family-poppins">
          {{$t('Buy.search.Interested')}}
        </h4>
        <p>
          {{$t('Buy.search.discoverHow')}}
        </p>
        <router-link class="py-2 px-5 rounded-pill btn btn-outline-primary learn-more-btn" :to="{
            name: 'HelpInformation',
            params: {
              subsection: $t('Footer.Footer.Buyers').toLowerCase(),
              type: $t('HelpCenter.Buyer.BuyWithUs')
                .replaceAll(' ', '-')
                .toLowerCase()
            }}">
          {{$t('global.label.learnMore')}}
        </router-link>
      </div>
      <!-- do you have questions -->
      <div class="rounded shadow-sm border border-primary d-flex flex-column justify-content-center align-items-center text-center p-3 gap-2" style="height:439px;" v-else-if="idx === 10">
        <img src="@/assets/img/car-step-1.svg" alt="Car Icon" class="img-fluid" width="52px"/>
        <h4 class="family-poppins">
          {{$t('Buy.search.HaveQuestions')}}
        </h4>
        <p>
          {{$t('Buy.search.HereForYou')}}
        </p>
        <button class="py-2 px-5 rounded-pill btn btn-outline-primary learn-more-btn" @click="openModal()">
          {{$t('global.label.askNow')}}
        </button>
      </div>
      <TheCarCard
        :carDetail="carDetail"
        :price="carDetail.Price"
        :includeFees="includeFees"
        wishlist
        class="mx-auto"
        v-else
      />
      <!-- <TheCarCard
          :carDetail="carDetail"
          wishlist
          buttonType="reserve"
        ></TheCarCard> -->
    </div>
    <!--
    <b-col class="text-center py-5">
      <b-button
        v-if="listings.length > 6"
        @click="scrollToTop()"
        variant="primary"
        pill
      >
        {{ $t("search.label.backToTop") }}
      </b-button>
    </b-col>
-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    TheCarCard: () => import("@/components/global/TheCarCard.vue"),
  },
  props: ["listings"],
  computed: {
    ...mapGetters([
      'getIncludeFees'
    ]),
    includeFees() {
      return this.getIncludeFees;
    },
    listingsWithExtraInfoCards() {
      const listingsWithExtraInfoCards = this.listings
      if (listingsWithExtraInfoCards[4] != null) {
        listingsWithExtraInfoCards.splice(4, 0, null);
      }
      if (listingsWithExtraInfoCards[10] != null) {
        listingsWithExtraInfoCards.splice(10, 0, null);
      }
      console.log(listingsWithExtraInfoCards)
      return listingsWithExtraInfoCards;
    }
  },
  methods: {
    ...mapActions(['setCustomerServiceModal']),
    openModal() {
      this.setCustomerServiceModal(true)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getPrice(prices) {
      return JSON.parse(prices)[0].Price;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  padding: 0;
}
.card-body {
  padding: 0;
}
.search-card-wrapper {
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
}
.search-card {
  width: calc((100% / 3) - 11px);
  transition: 0.3s;
  @media (max-width: 991px) {
    width: calc((100% / 2) - 8px);
  }
  @media (max-width: 575px) {
    width: 100%;
  }
  &:hover {
    box-shadow: 0 5px 14px -3px #b9b9b9;
  }
}

.learn-more-btn {
  &:hover {
    color:white!important;
  }
}
</style>
