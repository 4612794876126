<template>
  <b-modal
        hide-header
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
        centered
        id="messageSendSuccess"
      >
        <template #default="{ hide }">
          <div>
            <b-button class="close px-2 pb-2" @click="hide()">x</b-button>
          </div>
          <div class="text-center m-3">
            <h3>{{ $t("Contact.MessageSuccess") }}</h3>

            <div class="mt-4">
              <b-link @click="$router.go(0)">
                {{ $t("Contact.BackLinkText") }}
              </b-link>
            </div>
          </div>
        </template>
      </b-modal>
</template>

<script>
export default {

}
</script>

<style>

</style>